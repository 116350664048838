<template>
	<div id="app">
		<headerVue v-if="show" :scroll="scrollShow"></headerVue>
		<ydHeader v-if="!show"></ydHeader>
		<router-view />
		<bottomVue v-if="show"></bottomVue>
		<ydBottom v-if="!show"></ydBottom>
	</div>
</template>

<script>
	import headerVue from "./components/header.vue";
	import bottomVue from "./components/bottom.vue";
	import ydHeader from "./components/yd_header.vue";
	import ydBottom from "./components/yd_bottom.vue";
	export default {
		components: {
			headerVue,
			bottomVue,
			ydHeader,
			ydBottom
		},
		data() {
			return {
				scrollShow: false,
				show: false,
				scroll: 0,
			}
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
			let tabIndex = this.$cookies.get("22tab");
			console.log('tabIndex', tabIndex);
			this.isSystem();
			window.onload = function() {
				document.addEventListener('touchstart', function(event) {
					if (event.touches.length > 1) {
						event.preventDefault()
					}
				})
				document.addEventListener('gesturestart', function(event) {
					event.preventDefault()
				})
			}
		},
		methods: {
			handleScroll() {
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop
				// console.log('this.scroll',this.scroll)
				let tabIndex = this.$cookies.get("22tab");
				// console.log('tabIndex',tabIndex)
				if (tabIndex == 0 || tabIndex == 1) {
					if (this.scroll > 600) {
						this.scrollShow = true;
					} else {
						this.scrollShow = false;
					}

				} else if (tabIndex == 3 || tabIndex == 4) {
					if (this.scroll > 200) {
						this.scrollShow = true;
					} else {
						this.scrollShow = false;
					}

				} else if (tabIndex == 5) {
					if (this.scroll > 600) {
						this.scrollShow = true;
					} else {
						this.scrollShow = false;
					}

				}
			},
			isSystem() {
				let system = {};
				let p = navigator.platform;
				system.win = p.indexOf("Win") == 0;
				system.mac = p.indexOf("Mac") == 0;
				system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
				if (system.win || system.mac || system.xll) { //电脑
					this.show = true;
				} else { //手机
					this.show = false;
				}
			}
		}
	}
</script>

<style lang="scss">
	html,
	body {
		margin: 0;
		padding: 0;
		touch-action: pan-y;
	}
</style>
