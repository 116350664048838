<template>
	<div class="home">
		<div v-if="show">
			<el-carousel :height="screenheight" arrow="never">
				<el-carousel-item v-for="(item,index) in imgList" :key="index">
					<img :src="item.image_text" alt="" style="height:100%; width:100%;">
				</el-carousel-item>
			</el-carousel>
			<div class="dc wow bounceInUp" data-wow-duration="2s">
				<img class="dc_logo wow bounceInUp" data-wow-duration="2s" src="../assets/home/22dc.png" alt="">
				<div class="dc_title wow bounceInUp" data-wow-duration="2s">一站式生活服务体验，你想要的全都有</div>
				<div class="dc_text">
					<div class="dc_imgs wow bounceInUp" data-wow-duration="2s">
						<img class="dc_img" src="../assets/home/dc-1.png" alt="">
					</div>
					<div class="dc_content wow bounceInUp" data-wow-duration="2s">
						<div class="dc_content_text">
							<div class="dc_icon">
								<img src="../assets/home/dc-2.png" alt="">
								<span>品质高</span>
							</div>
							<div class="dc_icon">
								<img src="../assets/home/dc-2.png" alt="">
								<span>很实用</span>
							</div>
							<div class="dc_icon">
								<img src="../assets/home/dc-2.png" alt="">
								<span>成本低</span>
							</div>
							<div class="dc_icon">
								<img src="../assets/home/dc-2.png" alt="">
								<span>服务好</span>
							</div>
						</div>
						<div class="dc_value">
							22度巢社区生活服务驿站运用互联网、大数据、人工智能等技术，将线上线下进行融合联动、不同业态进行融合创新，通过物联网+站长体系，不断拓展服务边界，丰富消费场景，高水平匹配消费者多元需求，打造超值、优惠、贴心服务，让用户在家门口就能享受高质价比的一站式购物与生活服务体验。
						</div>
					</div>
				</div>
			</div>
			<homeFn></homeFn>
			<div class="st">
				<div class="st_left wow bounceInUp" data-wow-duration="3s" data-wow-delay="1s">
					<img class="st_logo" src="../assets/home/st_logo.png" alt="">
					<div class="st_text">
						<div class="st_content" v-for="(item,index) in dataList" :key="index">
							<img class="st_icon1" :src="require('../assets/home/st-'+(index+1)+'.png')" alt="">
							<img class="st_icon2" :src="require('../assets/home/st-'+(index+1)+'-1.png')" alt="">
							<div class="st_value">
								<span>{{item.value}}</span>
								<span>{{item.content}}</span>
							</div>
						</div>
					</div>
				</div>
				<img class="st_iphone wow bounceInUp" data-wow-duration="3s" data-wow-delay="1s" src="../assets/home/st.png"
					alt="">
			</div>
		</div>
		<ydHome v-if="!show"></ydHome>
	</div>
</template>

<script>
	import homeFn from '../components/home-fn.vue';
	import ydHome from './yd_home.vue';
	export default {
		components: {
			homeFn,
			ydHome
		},
		data() {
			return {
				screenheight: document.documentElement.clientWidth/(1920/920)  + 'px',
				imgList: [],
				show:true,
				dataList: [{
						value: '线上下单',
						content: '站长免费配送',
					},
					{
						value: '多门店自由切换',
						content: '到哪都能购买',
					},
					{
						value: '下单送积分',
						content: '积分商城换好物',
					},
					{
						value: '家政保洁',
						content: '维修服务应有尽有',
					},
					{
						value: '线上线下',
						content: '统一库存集中管理',
					},
					{
						value: '自营+商家入驻',
						content: '让商品销售多条路',
					}
				]
			}
		},
		mounted() {
			this.$router.afterEach((to, from, next) => {
				window.scrollTo(0, 0)
			})
			new this.$wow.WOW().init()
			this.getData();
			this.isSystem();
		},
		methods: {
			isSystem() {
				let system = {};
				let p = navigator.platform;
				system.win = p.indexOf("Win") == 0;
				system.mac = p.indexOf("Mac") == 0;
				system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
				if (system.win || system.mac || system.xll) { //电脑
					this.show = true;
				} else { //手机
					this.show = false;
				}
			},
			onMouseOver(e) {
				console.log(e)
			},
			onMouseOut(e) {
				console.log(e)
			},
			getData() {
				this.http.get('/api/index').then(res => {
					console.log(res);
					this.imgList = [];
					res.data.banner.forEach((item, index) => {
						if (item.type == 1) {
							this.imgList.push(item)
						}
					})
				})
			}
		}
	}
</script>

<style>
	.home {
		width: 1920px;
	}

	.el-carousel__button {
		width: 18px !important;
		height: 18px !important;
		border-radius: 18px !important;
		background-color: #FFFFFF !important;
		opacity: 0.5 !important;
	}

	.is-active .el-carousel__button {
		width: 48px !important;
		height: 18px !important;
		border-radius: 18px !important;
		background-color: #FFFFFF !important;
		opacity: 1 !important;
	}

	.dc {
		width: 1920px;
		height: 913px;
		background: #FFFFFF;
		text-align: center;
	}

	.dc_logo {
		width: 354px;
		height: 62px;
		margin-top: 108px;
	}

	.dc_title {
		font-size: 40px;
		font-weight: 600;
		color: #121212;
		line-height: 56px;
		margin-top: 20px;
	}

	.dc_text {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 150px;
		margin-top: 80px;
	}

	.dc_imgs {
		width: 607px;
		height: 479px;
		overflow: hidden;
	}

	.dc_img {
		width: 607px;
		height: 479px;
	}

	.dc_img {
		/* 过渡配置 */
		transition: all .5s ease .1s;
	}

	.dc_img:hover {
		/* 放大1.1倍 */
		transform: scale(1.1);
	}


	.dc_content {
		width: 895px;
		margin-left: 118px;
	}

	.dc_content_text {
		display: flex;
		align-items: center;
	}

	.dc_icon {
		display: flex;
		align-items: center;
		margin-right: 80px;
	}

	.dc_icon img {
		width: 34px;
		height: 34px;
	}

	.dc_icon span {
		font-size: 32px;
		font-weight: 600;
		color: #333333;
		line-height: 45px;
		margin-left: 12px;
	}

	.dc_value {
		margin-top: 40px;
		font-size: 24px;
		font-weight: 400;
		color: #666666;
		line-height: 48px;
		text-align: left;
	}

	/* 线上生态 */
	.st {
		width: 1920px;
		height: 814px;
		background: url('../assets/home/st_bg.png');
		background-size: 100% 100%;
		display: flex;
	}

	.st_logo {
		width: 354px;
		height: 62px;
		margin-top: 108px;
		margin-left: 783px;
	}

	.st_text {
		width: 1345px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 173px;
	}

	.st_content {
		min-width: 290px;
		display: flex;
		align-items: center;
		margin-bottom: 112px;
		margin-left: 130px;
	}

	.st_content .st_icon1 {
		width: 80px;
		height: 80px;
		display: block;
	}

	.st_content .st_icon2 {
		width: 80px;
		height: 80px;
		display: none;
	}

	.st_content:hover .st_icon1 {
		display: none;
	}

	.st_content:hover .st_icon2 {
		display: block;
	}

	.st_value {
		margin-left: 20px;
	}

	.st_content:hover .st_value span {
		color: #D2393E;
	}

	.st_value span {
		display: block;
		font-size: 24px;
		font-weight: 400;
		color: #333333;
		line-height: 40px;
	}

	.st_iphone {
		width: 425px;
		height: 740px;
		margin-top: 74px;
		margin-left: 30px;
	}
</style>
