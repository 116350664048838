<template>
	<div class="top-tab">
		<div :class="value!=2?'tab':'tabs'" v-if="!scrollShow">
			<img class="logo" src="../assets/home/logo.png" alt="" v-if="value!=2" @click="getTab(0)">
			<img class="logo" src="../assets/home/hlogo.png" alt="" v-else @click="getTab(0)">
			<div class="tab-text">
				<div class="text" v-for="(item,index) in tabList" :key="index" @click="getTab(index)">
					<div :class="value!=2?'title':'titles'">{{item.title}}</div>
					<img v-if="item.show&&value!=2" class="hudu" src="../assets/home/hudu.png" alt="">
					<img v-if="item.show&&value==2" class="hudu" src="../assets/home/hhudu.png" alt="">
				</div>
			</div>
		</div>
		<div class="tab" v-if="scrollShow" style="background: #3e414f;">
			<img class="logo" src="../assets/home/logo.png" alt="" @click="getTab(0)">
			<div class="tab-text">
				<div class="text" v-for="(item,index) in tabList" :key="index" @click="getTab(index)">
					<div class="title">{{item.title}}</div>
					<img v-if="item.show" class="hudu" src="../assets/home/hudu.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['scroll'],
		data() {
			return {
				scrollShow: false,
				value: 0,
				show: false,
				tabList: [{
						title: '首页',
						show: true,
						url: "/",
						urls: "/",
					},
					{
						title: '关于我们',
						show: false,
						url: "about",
						urls: "/about",
					},
					{
						title: '赋能体系',
						show: false,
						url: "energize",
						urls: "/energize",
					},
					{
						title: '合作加盟',
						show: false,
						url: "partner",
						urls: "/partner",
					},
					{
						title: '最新动态',
						show: false,
						url: "journalism",
						urls: "/journalism",
					},
					{
						title: '诚聘英才',
						show: false,
						url: "recruit",
						urls: "/recruit",
					}
				]
			}
		},
		watch: {
			$route(to, from) {
				let tabIndex = this.$cookies.get("22tab") || 0;
				this.tabList.forEach((item, index) => {
					if (tabIndex == index) {
						item.show = true;
						this.value = index;
					} else {
						item.show = false;
					}
				})
			},
			scroll(val, naval) {
				console.log('val',val)
				this.scrollShow = val;
			},
		},
		mounted() {
			let url = window.location.hash;
			let path = url.slice(1, url.length);
			console.log('url', url)
			this.tabList.forEach((item, index) => {
				if (path == item.urls) {
					item.show = true;
					this.value = index;
				} else {
					item.show = false;
				}
			})
		},
		methods: {
			getTab(e) {
				this.tabList.forEach((item, index) => {
					if (e == index) {
						item.show = true;
						this.$cookies.set("22tab", e);
						this.value = e;
					} else {
						item.show = false;
					}
				})

				this.$router.push({
					path: this.tabList[e].url
				});
			}
		}
	}
</script>

<style>
	body {
		padding: 0;
		margin: 0;
	}

	.top-tab {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.tab {
		width: 1694px;
		height: 98px;
		padding: 0 113px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 999;
		background: linear-gradient(360deg, rgba(0,0,0,0) 0%, rgba(18,18,18,0.8) 100%);
	}
	
	.tabs {
		width: 1694px;
		height: 98px;
		padding: 0 113px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 999;
		background-color: #FFFFFF;
	}

	.tab .logo {
		width: 99px;
		height: 50px;
		margin: 0 37px;
	}
	
	.tabs .logo {
		width: 99px;
		height: 50px;
		margin: 0 37px;
	}

	.tab-text {
		display: flex;
	}

	.text {
		margin: 0 37px;
		font-size: 20px;
		font-weight: 400;
		position: relative;
	}

	.hudu {
		width: 19px;
		height: 7px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -15px;
	}

	.title {
		color: #FFFFFF;
	}

	.titles {
		color: #333333;
	}

	.title:hover {
		transform: translateY(-3px);
		-webkit-transform: translateY(-3px);
		-moz-transform: translateY(-3px);
	}

	.titles:hover {
		transform: translateY(-3px);
		-webkit-transform: translateY(-3px);
		-moz-transform: translateY(-3px);
	}
</style>
