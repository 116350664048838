<template>
	<div class="fn">
		<div class="tx">
			<img class="tx_logo wow bounceInUp" data-wow-duration="2s" src="../assets/home/tx.png" alt="">
			<div class="tx_title wow bounceInUp" data-wow-duration="2s">在家门口就能享受高质价比的一站式购物与生活服务体验</div>
			<el-carousel :height="screenheight" arrow="never" :interval="5000">
				<el-carousel-item>
					<div class="tx_text wow bounceInUp" data-wow-duration="2s">
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-1.png" alt="">
							<div class="tx_value">
								<p>线下门店</p>
								<span>尖货好物，物美价优</span>
							</div>
						</div>
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-2.png" alt="">
							<div class="tx_value">
								<p>线上商城</p>
								<span>每日一团，配送到家</span>
							</div>
						</div>
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-3.png" alt="">
							<div class="tx_value">
								<p>甩手掌柜</p>
								<span>全时段满足及时需求</span>
							</div>
						</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="tx_text wow bounceInUp" data-wow-duration="2s">
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-4.png" alt="">
							<div class="tx_value">
								<p>用户圈</p>
								<span>完整画像，洞察需求</span>
							</div>
						</div>
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-5.png" alt="">
							<div class="tx_value">
								<p>站长服务</p>
								<span>贴近贴心，管家服务</span>
							</div>
						</div>
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-6.png" alt="">
							<div class="tx_value">
								<p>资源代理</p>
								<span>电信联通，业务代理</span>
							</div>
						</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="tx_text wow bounceInUp" data-wow-duration="2s">
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-7.png" alt="">
							<div class="tx_value">
								<p>近场交流</p>
								<span>用户圈子，交流大厅</span>
							</div>
						</div>
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-8.png" alt="">
							<div class="tx_value">
								<p>智能家居</p>
								<span>智慧空间，慧享生活</span>
							</div>
						</div>
						<div class="tx_content">
							<img class="tx_icon" src="../assets/home/tx-9.png" alt="">
							<div class="tx_value">
								<p>本地生活</p>
								<span>业态聚合，便民生态</span>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
			<div id="btns" class="tx_btn wow bounceInUp" data-wow-duration="2s" @click="getUrl">查看更多
				<img class="tx_btnJc1" src="../assets/home/jt.png">
				<img class="tx_btnJc2" src="../assets/home/jiantous.png">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				screenheight: document.documentElement.clientWidth/(1920/550)  + 'px',
				show: true,
			}
		},
		mounted() {
			console.log(this.screenheight);
		},
		methods: {
			getUrl() {
				this.$cookies.set("22tab", 2);
				this.$router.push({
					path: 'energize'
				});
			}
		}
	}
</script>

<style>
	.fn .el-carousel__button {
		width: 18px !important;
		height: 18px !important;
		border-radius: 18px !important;
		background-color: #D2393E !important;
		opacity: 0.3 !important;
	}

	.fn .is-active .el-carousel__button {
		width: 48px !important;
		height: 18px !important;
		border-radius: 18px !important;
		background-color: #D2393E !important;
		opacity: 1 !important;
	}

	.tx {
		width: 1920px;
		height: 929px;
		background-color: #FDF7F7;
		text-align: center;
	}

	.tx_logo {
		width: 354px;
		height: 62px;
		margin-top: 108px;
	}

	.tx_title {
		font-size: 40px;
		font-weight: 600;
		color: #121212;
		line-height: 56px;
		margin-top: 20px;
	}

	.tx_text {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: 68px;
	}

	.tx_icon {
		width: 175px;
		height: 175px;
	}

	.tx_value {
		margin-top: 23px;
	}

	.tx_value p {
		font-size: 32px;
		font-weight: 600;
		color: #333333;
	}

	.tx_value span {
		font-size: 24px;
		font-weight: 400;
		color: #666666;
	}

	.tx_value p:hover {
		color: #D2393E;
	}

	.tx_value span:hover {
		color: #D2393E;
	}

	.tx_btn {
		width: 310px;
		height: 80px;
		border-radius: 54px;
		border: 2px solid #D2393E;
		font-size: 32px;
		font-weight: 500;
		color: #D2393E;
		line-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		margin-top: 20px;
	}

	.tx_btn:hover {
		background-color: #D2393E;
		color: #FFFFFF;
	}

	.tx_btn .tx_btnJc1 {
		width: 15px;
		height: 26px;
		margin-left: 11px;
		display: block;
	}

	.tx_btn .tx_btnJc2 {
		width: 15px;
		height: 26px;
		margin-left: 11px;
		display: none;
	}

	.tx_btn:hover .tx_btnJc1 {
		display: none;
	}

	.tx_btn:hover .tx_btnJc2 {
		display: block;
	}
</style>
