import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from './utils/http.js'
import axios from 'axios'
import Cookies from "js-cookie";
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'

Vue.prototype.$wow = wow;

Vue.prototype.$cookies = Cookies;
Vue.prototype.http = http;


Vue.use(ElementUI);
Vue.use(axios)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
