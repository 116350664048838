<template>
	<div>
		<div class="bot">
			<div class="bot-top">
				<img class="text_logo" :src="dataList.logo" alt="">
				<div class="bot-img">
					<img :src="dataList.xcx" alt="">
					<img :src="dataList.dyh" alt="">
					<img :src="dataList.fwh" alt="">
				</div>
			</div>
			<div class="text">
				<div class="text_title">联系我们</div>
				<div class="text_content">
					<span>电话:{{dataList.mobile}}</span>
					<span>邮箱:{{dataList.email}}</span>
					<span>地址:{{dataList.address}}</span>
				</div>
			</div>
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="text-decoration:none;"><div class="bot_text">备案号：豫ICP备2023007088号</div></a>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataList: {},
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getData() {
				this.http.get('/api/index').then(res => {
					console.log(res);
					this.dataList = res.data.foot;
				})
			}
		}
	}
</script>

<style scoped>
	.bot {
		width: calc(780px*(1920/780));
		height: calc(530px*(1920/780));
		background: url('../assets/home/bot-bg.png');
		background-size: 100% 100%;
	}

	.bot-top {
		width: calc(700px*(1920/780));
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: calc(50px*(1920/780));
		padding-bottom: calc(30px*(1920/780));
		border-bottom: calc(1px*(1920/780)) solid #474747;
	}

	.text_logo {
		width: calc(160px*(1920/780));
		height: auto;
		margin-left: calc(10px*(1920/780));
	}

	.bot-img img {
		width: calc(116px*(1920/780));
		height: calc(138px*(1920/780));
		margin: 0 calc(5px*(1920/780));
	}

	.bot .text {
		width: calc(700px*(1920/780));
		margin: auto;
		padding-top: calc(30px*(1920/780));
		padding-bottom: calc(30px*(1920/780));
		padding-left: calc(10px*(1920/780));
		border-bottom: calc(1px*(1920/780)) solid #474747;
		position: static;
	}

	.text_title {
		font-size: calc(28px*(1920/780));
		font-weight: 400;
		color: #FFFFFF;
		opacity: 1;
	}
	.text_content{
		padding-top: calc(30px*(1920/780));
	}
	.text_content span{
		font-size: calc(22px*(1920/780));
		font-weight: 400;
		line-height: calc(33px*(1920/780));
		color: #FFFFFF;
		opacity: 0.4;
	}
	.bot .bot_text{
		font-size: calc(22px*(1920/780));
		font-weight: 400;
		line-height: calc(45px*(1920/780));
		color: #FFFFFF;
		opacity: 0.5;
		padding-top: calc(18px*(1920/780));
		padding-left: calc(50px*(1920/780));
		height: calc(70px*(1920/780));
	}
</style>
