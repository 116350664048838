<template>
	<div class="yd_home">
		<el-carousel height="312px" arrow="never" style="z-index: 1;">
			<el-carousel-item v-for="(item,index) in imgList" :key="index">
				<img :src="item.image_text" alt="" style="height:100%; width:100%;z-index: 1;">
			</el-carousel-item>
		</el-carousel>
		<div class="yd_dc wow bounceInUp" data-wow-duration="2s">
			<img class="yd_dc_logo wow bounceInUp" data-wow-duration="2s" src="../assets/home/22dc.png" alt="">
			<div class="yd_dc_title wow bounceInUp" data-wow-duration="2s">一站式生活服务体验，你想要的全都有</div>
			<div class="dc_content_text wow bounceInUp" data-wow-duration="2s">
				<div class="dc_icon">
					<img src="../assets/home/dc-2.png" alt="">
					<span>品质高</span>
				</div>
				<div class="dc_icon">
					<img src="../assets/home/dc-2.png" alt="">
					<span>很实用</span>
				</div>
				<div class="dc_icon">
					<img src="../assets/home/dc-2.png" alt="">
					<span>成本低</span>
				</div>
				<div class="dc_icon">
					<img src="../assets/home/dc-2.png" alt="">
					<span>服务好</span>
				</div>
			</div>
			<img class="dc_img wow bounceInUp" data-wow-duration="2s" src="../assets/home/dc-1.png" alt="">
			<div class="dc_value wow bounceInUp" data-wow-duration="2s">
				22度巢社区生活服务驿站运用互联网、大数据、人工智能等技术，将线上线下进行融合联动、不同业态进行融合创新，通过物联网+站长体系，不断拓展服务边界，丰富消费场景，高水平匹配消费者多元需求，打造超值、优惠、贴心服务，让用户在家门口就能享受高质价比的一站式购物与生活服务体验。
			</div>
		</div>
		<div class="yd_fn">
			<div class="tx">
				<img class="tx_logo wow bounceInUp" data-wow-duration="2s" src="../assets/home/tx.png" alt="">
				<div class="tx_title wow bounceInUp" data-wow-duration="2s">在家门口就能享受高质价比的一站式购物与生活服务体验</div>
				<el-carousel height="200px" arrow="never" :interval="5000">
					<el-carousel-item>
						<div class="tx_text wow bounceInUp" data-wow-duration="2s">
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-1.png" alt="">
								<div class="tx_value">
									<p>线下门店</p>
									<span>尖货好物，物美价优</span>
								</div>
							</div>
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-2.png" alt="">
								<div class="tx_value">
									<p>线上商城</p>
									<span>每日一团，配送到家</span>
								</div>
							</div>
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-3.png" alt="">
								<div class="tx_value">
									<p>甩手掌柜</p>
									<span>全时段满足及时需求</span>
								</div>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="tx_text wow bounceInUp" data-wow-duration="2s">
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-4.png" alt="">
								<div class="tx_value">
									<p>用户圈</p>
									<span>完整画像，洞察需求</span>
								</div>
							</div>
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-5.png" alt="">
								<div class="tx_value">
									<p>站长服务</p>
									<span>贴近贴心，管家服务</span>
								</div>
							</div>
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-6.png" alt="">
								<div class="tx_value">
									<p>资源代理</p>
									<span>电信联通，业务代理</span>
								</div>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="tx_text wow bounceInUp" data-wow-duration="2s">
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-7.png" alt="">
								<div class="tx_value">
									<p>近场交流</p>
									<span>用户圈子，交流大厅</span>
								</div>
							</div>
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-8.png" alt="">
								<div class="tx_value">
									<p>智能家居</p>
									<span>智慧空间，慧享生活</span>
								</div>
							</div>
							<div class="tx_content">
								<img class="tx_icon" src="../assets/home/tx-9.png" alt="">
								<div class="tx_value">
									<p>本地生活</p>
									<span>业态聚合，便民生态</span>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div id="btns" class="tx_btn wow bounceInUp" data-wow-duration="2s" @click="getUrl">查看更多
					<img class="tx_btnJc1" src="../assets/home/jt.png">
					<img class="tx_btnJc2" src="../assets/home/jiantous.png">
				</div>
			</div>
		</div>
		<div class="yd_st wow bounceInUp" data-wow-duration="2s">
			<img class="yd_st_logo wow bounceInUp" data-wow-duration="2s" src="../assets/home/st_logo.png" alt="">
			<div class="yd_st_text wow bounceInUp" data-wow-duration="2s">
				<div class="yd_st_left wow bounceInUp" data-wow-duration="2s">
					<div class="yd_st_content" v-for="(item,index) in dataList" :key="index">
						<img class="yd_st_icon" :src="require('../assets/yd-home/yd_st_'+(index)+'.png')" alt="">
						<div class="yd_st_value">{{item.value}}</div>
					</div>
				</div>
				<img class="yd_st_img wow bounceInUp" data-wow-duration="2s" src="../assets/yd-home/yd_st_img.png" alt="" style="z-index: 1;">
			</div>
		</div>
	</div>
</template>

<script>
	import ydHome from './yd_home.vue';
	export default {
		components: {
			ydHome
		},
		data() {
			return {
				imgList: [],
				show: true,
				dataList: [{
						value: '线上下单',
						content: '站长免费配送',
					},
					{
						value: '多门店自由切换',
						content: '到哪都能购买',
					},
					{
						value: '下单送积分',
						content: '积分商城换好物',
					},
					{
						value: '家政保洁',
						content: '维修服务应有尽有',
					},
					{
						value: '线上线下',
						content: '统一库存集中管理',
					},
					{
						value: '自营+商家入驻',
						content: '让商品销售多条路',
					}
				]
			}
		},
		mounted() {
			this.$router.afterEach((to, from, next) => {
				window.scrollTo(0, 0)
			})
			new this.$wow.WOW().init()
			this.getData();
			this.isSystem();
		},
		methods: {
			getUrl(){
				this.$cookies.set("22tab", 2);
				this.$router.push({
					path: 'ydEnergize'
				});
			},
			isSystem() {
				let system = {};
				let p = navigator.platform;
				system.win = p.indexOf("Win") == 0;
				system.mac = p.indexOf("Mac") == 0;
				system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
				if (system.win || system.mac || system.xll) { //电脑
					this.show = true;
				} else { //手机
					this.show = false;
				}
			},
			onMouseOver(e) {
				console.log(e)
			},
			onMouseOut(e) {
				console.log(e)
			},
			getData() {
				this.http.get('/api/index').then(res => {
					console.log(res);
					this.imgList = [];
					res.data.banner.forEach((item, index) => {
						if (item.type == 2) {
							this.imgList.push(item)
						}
					})
				})
			}
		}
	}
</script>

<style>
	.yd_home{
		padding-top: calc(87px*(1920/780));
	}
	.yd_home .el-carousel__button {
		width: calc(10px*(1920/780));
		height: calc(10px*(1920/780));
		border-radius: calc(10px*(1920/780));
		background-color: #FFFFFF !important;
		opacity: 0.5 !important;
	}

	.yd_home .is-active .el-carousel__button {
		width: calc(21px*(1920/780));
		height: calc(10px*(1920/780));
		border-radius: calc(10px*(1920/780));
		background-color: #FFFFFF !important;
		opacity: 1 !important;
	}

	.yd_dc {
		width: calc(780px*(1920/780));
		text-align: center;
	}

	.yd_dc_logo {
		width: calc(308px*(1920/780));
		margin-top: calc(60px*(1920/780));
	}

	.yd_dc_title {
		font-size: calc(26px*(1920/780));
		font-family: Alibaba PuHuiTi;
		font-weight: 400;
		color: #121212;
		opacity: 1;
		margin-top: calc(20px*(1920/780));
	}

	.yd_dc .dc_content_text {
		display: flex;
		align-items: center;
		padding: 0 calc(97px*(1920/780));
	}

	.yd_dc .dc_icon {
		margin: 0 calc(20px*(1920/780));
		display: flex;
		align-items: center;
		margin-top: calc(40px*(1920/780));
	}

	.yd_dc .dc_icon img {
		width: calc(24px*(1920/780));
		height: calc(24px*(1920/780));
	}

	.yd_dc .dc_icon span {
		margin-left: calc(10px*(1920/780));
		font-size: calc(22px*(1920/780));
		font-weight: 400;
		color: #333333;
		opacity: 1;
	}

	.yd_dc .dc_img {
		width: calc(344px*(1920/780));
		height: calc(270px*(1920/780));
		margin: auto;
		margin-top: calc(63px*(1920/780));
	}
	.yd_dc .dc_value{
		padding: calc(30px*(1920/780));
		font-size: calc(24px*(1920/780));
		font-weight: 400;
		line-height: calc(36px*(1920/780));
		color: #666666;
		opacity: 1;
	}
	/* fn */
	.yd_fn .el-carousel__button {
		width: calc(16px*(1920/780));
		height: calc(16px*(1920/780));
		border-radius: calc(16px*(1920/780));
		background-color: #D2393E !important;
		opacity: 0.3 !important;
	}
	
	.yd_fn .is-active .el-carousel__button {
		width: calc(48px*(1920/780));
		height: calc(16px*(1920/780));
		border-radius:calc(16px*(1920/780));
		background-color: #D2393E !important;
		opacity: 1 !important;
	}
	
	.yd_fn .tx {
		width: calc(780px*(1920/780));
		height: calc(820px*(1920/780));
		background-color: #FDF7F7;
		text-align: center;
	}
	
	.yd_fn .tx_logo {
		width: calc(308px*(1920/780));
		height:auto;
		margin-top: calc(80px*(1920/780));
	}
	
	.yd_fn .tx_title {
		font-size: calc(26px*(1920/780));
		font-weight: 400;
		color: #121212;
		opacity: 1;
		margin-top: calc(20px*(1920/780));
	}
	
	.yd_fn .tx_text {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: calc(118px*(1920/780));
	}
	
	.yd_fn .tx_icon {
		width: calc(78px*(1920/780));
		height: calc(78px*(1920/780));
	}
	
	.yd_fn .tx_value {
		margin-top:calc(24px*(1920/780));
	}
	
	.yd_fn .tx_value p {
		font-size: calc(28px*(1920/780));
		font-weight: 500;
		color: #333333;
		opacity: 1;
		margin: 0;
		margin-bottom: calc(10px*(1920/780));
	}
	
	.yd_fn .tx_value span {
		font-size: calc(22px*(1920/780));
		font-weight: 400;
		color: #666666;
		opacity: 1;
	}
	
	.yd_fn .tx_value p:hover {
		color: #D2393E;
	}
	
	.yd_fn .tx_value span:hover {
		color: #D2393E;
	}
	
	.yd_fn .tx_btn {
		width: calc(280px*(1920/780));
		height: calc(80px*(1920/780));
		border-radius: calc(80px*(1920/780));
		border: calc(2px*(1920/780)) solid #D2393E;
		font-size: calc(30px*(1920/780));
		font-weight: 400;
		color: #D2393E;
		line-height: calc(80px*(1920/780));
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		margin-top: calc(50px*(1920/780));
	}
	
	.yd_fn .tx_btn:hover {
		background-color: #D2393E;
		color: #FFFFFF;
	}
	.yd_fn .tx_btn .tx_btnJc1{
		width: calc(14px*(1920/780));
		height: calc(24px*(1920/780));
		margin-left: calc(11px*(1920/780));
		display: block;
	}
	.yd_fn .tx_btn .tx_btnJc2{
		width: calc(14px*(1920/780));
		height: calc(24px*(1920/780));
		margin-left: calc(11px*(1920/780));
		display: none;
	}
	
	.yd_fn .tx_btn:hover .tx_btnJc1{
		display: none;
	}
	.yd_fn .tx_btn:hover .tx_btnJc2{
		display: block;
	}
	/* 线上生态 */
	.yd_st{
		width: calc(780px*(1920/780));
		height: calc(814px*(1920/780));
		background: url('../assets/yd-home/yd_st_bg.png');
		background-size: 100% 100%;
		text-align: center;
	}
	.yd_st_logo{
		width: calc(308px*(1920/780));
		height: auto;
		margin-top: calc(90px*(1920/780));
	}
	.yd_st_text{
		display: flex;
		justify-content: space-between;
		margin-top: calc(81px*(1920/780));
	}
	.yd_st_left{
		padding-left: calc(75px*(1920/780));
		padding-top: calc(80px*(1920/780));
	}
	.yd_st_content{
		display: flex;
		align-items: center;
		margin-bottom: calc(34px*(1920/780));
	}
	.yd_st_icon{
		width: calc(40px*(1920/780));
		height: calc(40px*(1920/780));
		margin-right: calc(20px*(1920/780));
	}
	.yd_st_value{
		font-size: calc(24px*(1920/780));
		font-weight: 500;
		color: #333333;
		opacity: 1;
	}
	.yd_st_img{
		width: calc(332px*(1920/780));
		height: calc(578px*(1920/780));
		margin-right: calc(43px*(1920/780));
	}
</style>