<template>
	<div class="bottom">
		<div class="bot_text">
			<div class="text_left">
				<img class="text_logo" :src="dataList.logo" alt="">
				<div class="text_title">联系我们</div>
				<div class="text_content">
					<span>电话:{{dataList.mobile}}</span>
					<span>邮箱:{{dataList.email}}</span>
					<span>地址:{{dataList.address}}</span>
				</div>
			</div>
			<div class="text_img">
				<img :src="dataList.xcx" alt="">
				<img :src="dataList.dyh" alt="">
				<img :src="dataList.fwh" alt="">
			</div>
		</div>
		<div class="bot_heng"></div>
		<div class="bot_bot">
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="text-decoration:none;"><div class="bot_bot_text">备案号：豫ICP备2023007088号</div></a>
			<a href="https://www.junhebrand.cn/" target="_blank" style="text-decoration:none;"><div class="bot_bot_text">技术支持：君和数字创意</div></a>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataList: {},
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getData() {
				this.http.get('/api/index').then(res => {
					console.log(res);
					this.dataList = res.data.foot;
				})
			}
		}
	}
</script>

<style>
	.bottom {
		width: 1920px;
		height: 467px;
		background: url('../assets/home/bot-bg.png');
		background-size: 100% 100%;
	}

	.bot_text {
		width: 1920px;
		height: 384px;
		display: flex;
		justify-content: space-between;
	}

	.text_left {
		margin-left: 150px;
		margin-top: 60px;
	}

	.text_logo {
		width: 162px;
		height: 108px;
	}

	.text_title {
		margin-top: 40px;
		font-size: 28px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 40px;
	}

	.text_content {
		margin-top: 16px;
	}

	.text_content span {
		display: block;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
		opacity: 0.6;
	}
	
	.text_content span:hover {
		opacity: 1;
	}

	.text_img {
		margin-right: 150px;
		margin-top: 215px;
	}

	.text_img img {
		width: 116px;
		height: 138px;
		margin-left: 30px;
	}

	.bot_heng {
		width: 1620px;
		height: 1px;
		background-color: #FFFFFF;
		opacity: 0.1;
		margin: auto;
	}
	.bot_bot{
		width: 1620px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;
	}
	.bot_bot_text{
		font-size: 16px;
		font-weight: 300;
		color: #FFFFFF;
		opacity: 0.6;
	}
	
	.bot_bot_text:hover{
		opacity: 1;
	}
</style>
