<template>
	<div class="yd_tab_btn">
		<div class="tab-btn">
			<img class="yd_logo" src="../assets/yd-home/logo.png" alt="" @click="getUrl('/')">
			<img class="yd_tabs" id="yd_tabs" src="../assets/yd-home/tab.png" alt="" @click="getShow()">
			<img class="yd_tabss" id="yd_tabss" src="../assets/yd-home/lc-xx.png" alt="" @click="getShows()">
		</div>
		<div class="yd_tab" id="tab_text"></div>
		<div class="yd_tab_text" id="tab_texts">
			<div class="tab_text" v-for="(item,index) in tabList" :key="index" @click="getUrl(item.url)">
				{{item.title}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				tabList: [{
						title: '首页',
						show: true,
						url: "/",
						urls: "/",
					},
					{
						title: '关于我们',
						show: false,
						url: "ydAbout",
						urls: "/ydAbout",
					},
					{
						title: '赋能体系',
						show: false,
						url: "ydEnergize",
						urls: "/ydEnergize",
					},
					{
						title: '合作加盟',
						show: false,
						url: "ydPartner",
						urls: "/ydPartner",
					},
					{
						title: '最新动态',
						show: false,
						url: "ydJournalism",
						urls: "/ydJournalism",
					},
					{
						title: '诚聘英才',
						show: false,
						url: "ydRecruit",
						urls: "/ydRecruit",
					}
				]
			}
		},
		methods: {
			getUrl(url) {
				let yd_tabs=document.getElementById('yd_tabs');
				let yd_tabss=document.getElementById('yd_tabss');
				let tab_text=document.getElementById('tab_text');
				let tab_texts=document.getElementById('tab_texts');
				yd_tabs.style.display="block";
				yd_tabss.style.display="none";
				tab_text.style.right="calc(-780px*(1920/780))"
				tab_texts.style.right="calc(-780px*(1920/780))"
				this.$router.push({
					path: url
				});
			},
			getShow(){
				let yd_tabs=document.getElementById('yd_tabs');
				let yd_tabss=document.getElementById('yd_tabss');
				let tab_text=document.getElementById('tab_text');
				let tab_texts=document.getElementById('tab_texts');
				yd_tabs.style.display="none";
				yd_tabss.style.display="block";
				tab_text.style.right="0"
				tab_texts.style.right="0"
			},
			getShows(){
				let yd_tabs=document.getElementById('yd_tabs');
				let yd_tabss=document.getElementById('yd_tabss');
				let tab_text=document.getElementById('tab_text');
				let tab_texts=document.getElementById('tab_texts');
				yd_tabs.style.display="block";
				yd_tabss.style.display="none";
				tab_text.style.right="calc(-780px*(1920/780))"
				tab_texts.style.right="calc(-780px*(1920/780))"
			}
		}
	}
</script>

<style scoped>
	.yd_tab_btn{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background-color: #FFFFFF;
	}
	.tab-btn {
		width: calc(740px*(1920/780));
		display: flex;
		justify-content: space-between;
		padding: 0 calc(20px*(1920/780));
		padding-top: calc(21px*(1920/780));
		padding-bottom: calc(21px*(1920/780));
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background-color: #FFFFFF;
	}

	.yd_logo {
		width: calc(118px*(1920/780));
		height: calc(48px*(1920/780));
	}

	.yd_tabs {
		width: calc(46px*(1920/780));
		height: calc(46px*(1920/780));
		display: block;
		transition: all 1s;
	}
	.yd_tabss {
		width: calc(46px*(1920/780));
		height: calc(46px*(1920/780));
		display: none;
		transition: all 1s;
	}

	/* tab */
	.yd_tab {
		position: fixed;
		top: calc(88px*(1920/780));
		right: calc(-780px*(1920/780));
		width: calc(780px*(1920/780));
		min-height: 100vh;
		background: #000000;
		opacity: 0.85;
		z-index: 999;
		transition: all 1s;
	}

	.yd_tab_text {
		position: fixed;
		top: calc(88px*(1920/780));
		right: calc(-780px*(1920/780));
		width: calc(780px*(1920/780));
		height: 100vh;
		z-index: 999;
		padding-top: calc(30px*(1920/780));
		transition: all 1s;
	}

	.yd_tab_text .tab_text {
		width: calc(690px*(1920/780));
		height: calc(150px*(1920/780));
		line-height: calc(150px*(1920/780));
		color: #FFFFFF;
		opacity: 1;
		font-size: calc(36px*(1920/780));
		font-weight: 400;
		border-bottom: calc(1px*(1920/780)) solid #474747;
		margin: auto;
		z-index: 999;
	}

	.yd_tab .tab_text {
		width: calc(690px*(1920/780));
		height: calc(150px*(1920/780));
		line-height: calc(150px*(1920/780));
		color: #FFFFFF;
		opacity: 1;
		font-size: calc(36px*(1920/780));
		font-weight: 400;
		border-bottom: calc(1px*(1920/780)) solid #474747;
		margin: auto;
	}

	.yd_tab_text .tab_x {
		width: calc(50px*(1920/780));
		height: calc(50px*(1920/780));
		position: absolute;
		top: calc(40px*(1920/780));
		right: calc(50px*(1920/780));
	}
</style>
