import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/about.vue')
	},
	{
		path: '/partner',
		name: 'partner',
		component: () => import('../views/partner.vue')
	},
	{
		path: '/energize',
		name: 'energize',
		component: () => import('../views/energize.vue')
	},
	{
		path: '/recruit',
		name: 'recruit',
		component: () => import('../views/recruit.vue')
	},
	{
		path: '/journalism',
		name: 'journalism',
		component: () => import('../views/journalism.vue')
	},
	{
		path: '/newDetails',
		name: 'newDetails',
		component: () => import('../views/new-details.vue')
	},
	// 移动端
	{
		path: '/ydRecruit',
		name: 'ydRecruit',
		component: () => import('../views/yd_recruit.vue')
	},
	{
		path: '/ydJournalism',
		name: 'ydJournalism',
		component: () => import('../views/yd_journalism.vue')
	},
	{
		path: '/ydNewDetails',
		name: 'ydNewDetails',
		component: () => import('../views/yd_new_details.vue')
	},
	{
		path: '/ydPartner',
		name: 'ydPartner',
		component: () => import('../views/yd_partner.vue')
	},
	{
		path: '/ydEnergize',
		name: 'ydEnergize',
		component: () => import('../views/yd_energize.vue')
	},
	{
		path: '/ydAbout',
		name: 'ydAbout',
		component: () => import('../views/yd_about.vue')
	},
]

const router = new VueRouter({
	mode: 'hash',
	// base: process.env.BASE_URL,
	routes,
})

export default router
